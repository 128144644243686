body {
  color: #000;
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  max-width: 1300px;
  margin: 0 auto;
}

h2,
h3 {
  margin: 0;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 22px;
}

input {
  font-size: 16px !important;
}

@media screen and (max-width: 1200px) {
  .container {
    margin: 0 10px;
  }

  h2 {
    font-size: 18px;
  }
  
  h3 {
    font-size: 16px;
  }
}

.container img {
  max-width: 200px;
}

.container td {
  width: 100px;
  text-align: center;
}

.button {
  display: flex;
  outline: none;
  cursor: pointer;
  align-items: center;
  background: rgb(5, 120, 189);
  border: none;
  color: #fff;
  text-transform: uppercase;
  border-radius: 5px;
}

.button:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}