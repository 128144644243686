.backdrop_mobile_menu {
    background: #000;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: .8;
}