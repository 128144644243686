.header_prices {
    display: flex;
    justify-content: center;
    max-width: 1300px;
    margin: 0 auto;
}

.header_prices.custom-pdf {
    justify-content: start;
}

.header_prices svg {
    width: 200px;
    height: 150px;
}

@media screen and (max-width: 1200px) {
    .header_prices svg {
        width: 150px;
        height: 120px;
    }
}