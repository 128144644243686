.container_client {
    margin-bottom: 20px;
}

.container_client span {
    font-weight: bold; 
}

.container_client input {
    outline: none;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid #cecece;
    margin-left: 10px;
    min-width: 200px; 
}

.container_client.custom-style {
    margin: 20px auto 10px;
    padding-left: 20px;
}

@media (max-width: 768px) {
    .container_client input {
        max-width: 100%;
    }
}