.divider {
    width: 100%;
    height: 1px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
}

.button-remove {
    background: none;
    border: none
}

.button-remove svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
    fill: #ff5252;
}

.button-remove svg:hover {
    fill: red;
}

.container_price {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 30px;
    gap: 30px;
}

.hide-pdf {
    width: 0;
    opacity: 0;
    display: none
}

.left-pane {
    position: relative;
}

.left-pane::after {
    display: block;
    content: '';
    width: 1px;
    height: 60%;
    background: #cecece;
    position: absolute;
    right: -10px;
    top: calc(50% - 30%);
}

/* Lista do carrinho */

.right-pane {
    width: 100%;
}

.cart_item.first {
    margin: 40px 0 15px;
}

.cart_item {
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.cart_item .item_name {
    word-wrap: break-word;
    width: 100%;
    margin-bottom: 15px;
    font-size: 15px;
}

.cart_item:nth-child(odd) {
    background-color: #f3f3f3;
}

@media print {
    .cart_item:nth-child(odd) {
        background-color: #f3f3f3;
    }
}

.cart_item .item_prices {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    font-size: 14px;
}

.cart_item .observation {
    margin-top: 15px;
    font-size: 12px;
}

.cart_item .observation.mobile {
    margin-top: 0px !important;
}

.buttons_cart {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttons_cart div {
    display: flex;
    gap: 20px;
}

.cart_items.empty {
    height: 50vh;
    align-items: center;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1200px) {
    .container_price {
        flex-direction: column;
    }

    .left-pane .buttons-material {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;
    }

    .left-pane::after {
        display: none;
    }

    .left-pane .item-select {
        width: 50%;
    }

    .cart_item {
        padding: 10px;
    }

    .cart_item.first {
        margin: 25px 0 15px;
    }

    .cart_item .item_name {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .cart_item .item_prices {
        gap: 10px;
    }
}

@media screen and (max-width: 540px) {
    .cart_item .item_prices {
        flex-direction: column;
    }

    .price_row {
        display: flex;
        flex-direction: row;
        gap: 20px;

    }
}