.mobile_menu {
    width: 300px;
    border-radius: 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1200;
    background-color: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 20px 15px;
}

.mobile_menu header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu-toggle,
.close-menu {
    color: #000;
    border: none;
    background: none;
    font-size: 20px;
    margin-top: -2px;
    padding-left: 0;
}

.close-menu {
    font-size: 26px;
}

.menu-open {
    overflow: hidden;
}

.title-menu-mobile {
    display: flex;
    align-items: center;
    gap: 10px
}

.menu-toggle strong {
    font-size: 18px;
}

@media screen and (max-width: 1200px) {
    .mobile_menu .buttons-material {
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;
    }

    .mobile_menu .buttons-material .select-item {
        font-size: 14px;
    }
}