.container_user {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 30px auto 60px;
}

.container_user .content_user {
    display: flex;
    align-items: center;
    gap: 40px;
}

.container_user .content_user .user_name strong {
    font-size: 24px;
}

.container_user .content_user .user_name {
    font-size: 18px;
}

.container_user .content_user .user_created {
    font-size: 18px;
    margin-bottom: -3px;
}

.container_user .content_logout {
    display: flex;
    flex-direction: row;
}

.container_user .buttons_action {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 40px;
}

.container_user .button_logout {
    gap: 10px;
    font-size: 12px;
    font-weight: bold;
    height: fit-content;
    padding: 7px 5px;
}

.container_user .button_logout svg {
    display: block;
    width: 15px;
}

.container_user .button_logout:hover {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-delay: 0s;
    position: relative;
    opacity: .9;
}

.last-change {
    font-size: 14px;
}

@media screen and (max-width: 1200px) {
    .container_user {
        margin: 30px 10px 60px;
    }

    .last-change {
        font-size: 12px;
    }
}

@media screen and (max-width: 620px) {
    .container_user {
        margin: 30px 10px 15px;
    }
}

@media screen and (max-width: 420px) {
    .container_user .buttons_action {
        gap: 30px;
    }
}