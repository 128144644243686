.footer_prices {
    color: #000;
}

.footer_prices .content_footer {
    display: flex;
    justify-content: space-between;
    max-width: 1300px;
    margin: 0 auto;
    padding: 30px 0;
}

.footer_prices .content_footer div{
    display: flex;
    flex-direction: column;
}

.footer_prices .content_footer span {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    margin-bottom: 5px;
}

.footer_prices .content_footer span.razao_social {
    font-weight: bold;
    margin-bottom: 15px;
}

.footer_prices .content_footer span.whatsapp_contact svg{
    width: 15px;
    right: 10px;
    fill: #25d366;
}

.footer_prices .content_footer span.phone_contact svg,
.footer_prices .content_footer span.mail_contact svg{
    width: 15px;
    right: 10px;
    fill: rgb(5, 120, 189);
}

@media screen and (max-width: 1320px) {
    .footer_prices {
        padding: 0 10px;
    }
}

@media screen and (max-width: 767px) {
    .footer_prices {
        padding: 0 10px;
    }

    .footer_prices .content_footer {
        flex-direction: column;
        gap: 20px;
    }
}