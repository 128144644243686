.totalizer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    font-size: 14px;
    padding: 10px 20px 0;

}

.description-totalizer {
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    padding: 10px 20px;
}

.totalizer.list-price {
    border-top: 1px solid #cecece
}

.totalizer.border {
    border-top: 1px solid #cecece
}

.totalizer.discount .price_row {
    display: flex;
    align-items: center;
    gap: 20px;
}

.totalizer.discount .price_row input {
    outline: none;
    border: 1px solid #cecece;
    width: 50px;
    border-radius: 3px;
    text-align: center;
}


.totalizer .measure:not(.show),
.totalizer button {
    opacity: 0;
}

.totalizer .measure,
.totalizer .price.show {
    font-weight: bold;
}

@media screen and (max-width: 1200px) {
    .totalizer,
    .description-totalizer {
        padding: 10px 20px 10px 10px;
    }
}

@media screen and (max-width: 540px) {
    .totalizer {
        flex-direction: column;
    }
}