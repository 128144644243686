.header_login {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
}

.footer_login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 0 0;
    max-width: 360px;
    margin: 0 auto;
}

.footer_login .title_login {
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
}

.footer_login input {
    outline: none;
    width: calc(100% - 17px);
    height: 50px;
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    box-shadow: none;
    padding: 0;
    padding-left: 15px;
}

.footer_login input::placeholder,
.footer_login input:focus::placeholder,
.footer_login input:active::placeholder {
    color: #aaa;
}


.footer_login .input_name {
    margin-bottom: 10px;
}

.footer_login label {
    position: relative;
}

.footer_login .button_showPassword {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: none;
}

.footer_login .button_showPassword svg {
    width: 20px;
}

.footer_login .button_login {
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 20px;
    background: rgb(5, 120, 189);
    border: none;
    padding: 15px 0;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
}

.footer_login .button_login:hover {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-delay: 0s;
    position: relative;
    opacity: .9;
}

.logo_focal {
    max-width: 150px;
}