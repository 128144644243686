.back-to-top {
    cursor: pointer;
    background: none;
    display: flex;
    position: fixed;
    right: 80px;
    bottom: 80px;
    border: 1px solid #047BBC;
    border-radius: 100%;
    rotate: 90deg;
}

.back-to-top:hover {
    background: #047BBC;
}

.back-to-top:hover svg {
    fill: #fff
}

.back-to-top svg {
    fill: #047BBC;
    width: 24px;
    height: 32px;
}

@media screen and (max-width: 1200px) {
    .back-to-top {
        right: 20px;
        bottom: 40px;
    }
}
