.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
}

.loading-text {
    text-align: center;
    margin-bottom: 10px;
    color: #000;
}

.loading-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-icon svg {
    margin: auto;
    background: 'rgb(255, 255, 255)';
    shape-rendering: auto;
}