.container .select-item {
    font-size: 14px;
}

.item-search .button-remove-search {
    cursor: pointer;
    border: none;
    background: none;
}

.item-search .button-remove-search svg {
    width: 15px;
    height: 15px;
}

.table-item {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

.button-add-to-cart {
    width: 100%;
    justify-content: center;
    font-size: 12px;
    padding: 6px;
    margin-top: 10px;
}

@media screen and (min-width: 1200px) {
    .container .select-item {
        width: 300px;
    }

    .table-item.first {
        margin-top: 40px;
    }

}

@media screen and (max-width: 1200px){
    .button-add-to-cart {
        padding: 10px 0;
    }
}